import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import bearKings from "../../Assets/Projects/beer-kings.png";
import poopKings from "../../Assets/Projects/poop-kings.png";
import minio from "../../Assets/Projects/minio-java.png";
import agemed from "../../Assets/Projects/agemed_logo.png";
import hangman from "../../Assets/Projects/hangman.png";
import itTools from "../../Assets/Projects/it-tools.png";
import kafka from "../../Assets/Projects/kafka.png";
import ollama from "../../Assets/Projects/ollama.png";
import loginService from "../../Assets/Projects/login-service.png";

function Projects() {
  return (
      <Container fluid className="project-section">
        <Particle />
        <Container>
          <h1 className="project-heading">
            My Recent <strong className="purple">Works </strong>
          </h1>
          <p style={{ color: "white" }}>
            Here are a few projects I've worked on recently.
          </p>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>


            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={bearKings}
                  isBlog={false}
                  title="Beer Kings"
                  description="A Telegram Bot to implement on your group chats to measure Drinks Scores and tell your friends who drinks the most beers. Builted with SpringBoot and Telegram Bot Father."
                  ghLink="https://github.com/MrW1cked/BeerKings_Bot"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={poopKings}
                  isBlog={false}
                  title="Poop Kings"
                  description="The same as Beer Kings but for tracking other counts... you got the idea!"
                  ghLink="https://github.com/MrW1cked/PoopKings_Bot"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={minio}
                  isBlog={false}
                  title="Minio S3 File Upload"
                  description="A Spring Boot app using Minio S3 Services for users to upload and download files into a server."
                  ghLink="https://github.com/MrW1cked/File-Upload-Download-Java-MinIO-"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={kafka}
                  isBlog={false}
                  title="Spring Boot Kafka Consumer & Producer"
                  description="A Spring Boot app that consumes and produces messages to a Kafka Server."
                  ghLink="https://github.com/MrW1cked/KafkaConsumer-Producer"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={loginService}
                  isBlog={false}
                  title="JWT Login Service"
                  description="A Spring Boot app that provides a JWT Token for users to authenticate in other services."
                  ghLink="https://github.com/MrW1cked/JWT-Login"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={hangman}
                  isBlog={false}
                  title="HangMan Game"
                  description="A basic classic HangMan Game built with Python."
                  ghLink="https://github.com/MrW1cked/HangMan-Game"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={agemed}
                  isBlog={false}
                  title="AGEmed Medical Assistant"
                  description="A project for tracking patients in real-time with status updates and chat functionalities. Currently in development."
                  demoLink="https://www.agemed.pt"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={itTools}
                  isBlog={false}
                  title="IT Tools"
                  description="A open-source project made by @CorentinTh that i self-host on my server with tools for everyday use."
                  ghLink="https://github.com/CorentinTh/it-tools"
                  demoLink="https://tools.nunosousa.pt"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                  imgPath={ollama}
                  isBlog={false}
                  title="Ollama LLModel"
                  description="A LLM model from the Ollama project."
                  demoLink="https://gpt.nunosousa.pt"
              />
            </Col>
          </Row>
        </Container>
      </Container>
  );
}

export default Projects;